<template>
  <div>
    <v-app-bar app
               dark
               flat
               class="hidden-sm-and-down"
               color="transparent">

      <div class="d-flex justify-start align-content-center flex-wrap col-12">
        <v-img src="/base/img/mova_green.png"
               max-height="48"
               position="left center"
               contain
               class="mx-3">
        </v-img>
        <div>
        <v-menu offset-y >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"

                outlined
                color="primary"
                class="icon-height rounded-lg mr-3 ml-auto"
            >
              <v-icon
                  left
                  x-small
              >
                fa-chevron-down
              </v-icon>
              {{ lang }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item link class="v-list-item--active" @click.prevent="changeLanguage('nl')">
              <v-list-item-content>
                <v-list-item-title>nl</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click.prevent="changeLanguage('en')">
              <v-list-item-content>
                <v-list-item-title>en</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item link @click.prevent="changeLanguage('fr')">
              <v-list-item-content>
                <v-list-item-title>fr</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
            outlined
          color="primary"
          class="rounded-lg mr-5"
            @click.prevent="openfaq()"
          icon
        >
          <v-icon>
            fa-solid fa-question
          </v-icon>
        </v-btn>
        </div>
      </div>
    </v-app-bar>
    <v-app-bar app
               dark
               fixed
               height="100px"
               flat
               class="hidden-md-and-up"
               color="white">

      <div class="d-flex justify-start align-content-center justify-center flex-wrap w-100">
        <v-img src="/base/img/mova_green.png"
               max-height="48"
               position="center center"
               contain
               class="mx-3 col-4">
        </v-img>
        <div class="ml-auto col-4">
          <v-menu offset-y >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"

                outlined
                color="primary"
                class="icon-height rounded-lg mr-3 ml-auto "
              >
                <v-icon
                  left
                  x-small
                >
                  fa-chevron-down
                </v-icon>
                {{ lang }}
              </v-btn>
            </template>

            <v-list>
              <v-list-item link class="v-list-item--active" @click.prevent="changeLanguage('nl')">
                <v-list-item-content>
                  <v-list-item-title>nl</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click.prevent="changeLanguage('en')">
                <v-list-item-content>
                  <v-list-item-title>en</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item link @click.prevent="changeLanguage('fr')">
                <v-list-item-content>
                  <v-list-item-title>fr</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            outlined
            color="primary"
            class="rounded-lg mr-5"
            @click.prevent="openfaq()"
            icon
          >
            <v-icon>
              fa-solid fa-question
            </v-icon>
          </v-btn>
        </div>
      </div>
    </v-app-bar>

    <v-footer app padless>
      <div
          class="col-12 text-center"
      >
        <div class="d-flex justify-center align-center">
          <span class="font-weight-bold">&copy; PatientSupportR BV - {{version}}</span>
        </div>
        <div class="d-flex justify-center align-center">
          <span class="primary--text">{{$t(`app.tagline`)}}</span>
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
export default {
  i18n: i18n,
  name: "psr-header-initial",
  props: ['version'],
  data() {
    return {
      current: 'state.initial',
      mode: null,
      serial: null,
      lang: 'nl',
      view: false,
      pincode: null,
      resetDialog: false,
      provisioning: false,
      languages: ['en','nl', 'fr']
    }
  },
  methods: {
    changeLanguage(newLang) {

      this.lang = newLang;
      this.$i18n.locale = this.lang
      this.$vuetify.lang.current = this.lang
      this.$moment.locale(this.lang)
      localStorage.setItem('mova.lang',this.lang);
    },
    openfaq() {
      window.open('https://www.mova.care/nl/faq/', "_blank");
    }
  },
  mounted() {
    let stored_lang = localStorage.getItem('mova.lang');
    if (!stored_lang) {
      const navLang =  navigator.language || navigator.userLanguage;
      stored_lang = 'en'
      if (navLang.startsWith("nl")) {
        this.lang = 'nl'
      }
      if (navLang.startsWith('fr')) {
        this.lang = 'fr'
      }
      localStorage.setItem('mova.lang',this.lang);
    } else {
      this.lang = stored_lang
    }

    this.$i18n.locale = this.lang
    this.$vuetify.lang.current = this.lang
    this.$moment.locale(this.lang)

  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
}
.v-toolbar__content {
  padding: 0px !important;
}
.v-input {
  font-size: 1.5rem !important;
}
.fixed-font {
  font-size: 1.5rem !important;
}
.icon-height {
  min-height: 50px;
}
</style>
