<template>
  <div>
    <v-dialog v-model="resetDialog" max-width="900">
      <v-card>
        <v-card-text class="px-5 py-5 d-flex justify-center">{{ $t('clear.confirm') }}</v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn outlined color="primary" @click.prevent="resetDialog=false">{{$t('label.cancel')}}</v-btn>
          <v-btn outlined color="warning" @click.prevent="reallyResetAndClearStick()">{{$t('label.confirm')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar  :color="role === 'patient' ? 'primary' : 'secondary'"
        dark
        v-if="!drawer">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img src="/base/img/mova_white.png"
             height="48"
             contain
             position="right"
             class="px-3 mr-3 ml-auto">
      </v-img>
    </v-app-bar>
    <v-navigation-drawer
        :permanent="$vuetify.breakpoint.mdAndUp"
        app
        :width="$vuetify.breakpoint.smAndDown ? '90%' : '20%'"
        v-model="drawer"
        show
        dark
        :color="role === 'patient' ? 'primary' : 'secondary'"
    >
      <div class="d-flex flex-column pa-3" style="height: 100%;">
        <div d-flex flex-row>
          <v-img src="/base/img/mova_white.png"
                 height="48"
                 contain
                 position="left"
                 class="mx-3">
          </v-img>
          <v-app-bar-nav-icon v-if='$vuetify.breakpoint.smAndDown' class="ml-auto mr-5" @click="drawer = !drawer"></v-app-bar-nav-icon>
        </div>
        <div class="my-8">
          <v-card
              color="white"
              depressed
              class=" py-2 my-5 justify-start align-top rounded-lg text-uppercase font-weight-medium px-5  d-flex flex-row"
              :class="role === 'patient' ? 'primary--text' : 'secondary--text'"
              style="width: 100%;"
          >
            <v-icon
                small
                left
                :color="role === 'patient' ? 'primary' : 'secondary'"
                class="mr-3"
            >
              fa-solid fa-user
            </v-icon>
            <div class="wrap--te">
              {{ firstName }} {{lastName}}
            </div>

          </v-card>
        </div>
        <div>
          <v-btn
            outlined
            block
            @click.prevent="logout"
            :x-large="!drawer"
            class="my-1 justify-start rounded-lg text-md-body-2 text-h6"
          >
            <v-icon
              small
              left
              class="mr-3"
            >
              fa-solid fa-sign-out

            </v-icon>
            {{$t(`button.logout`)}}
          </v-btn>
        </div>
        <v-spacer></v-spacer>

        <div class="mb-8 d-flex flex-column justify-center align-start">
         <v-btn
              outlined
              block
              :x-large="!drawer"
              class="my-1 justify-start rounded-lg text-md-body-2 text-h6"
              @click.prevent="showMenu"
          >
            <v-icon
                small
                left
                class="mr-3"
            >
              fa-solid fa-bars
            </v-icon>
            {{$t(`button.menu`)}}
          </v-btn>
          <v-btn
              v-if="role === 'patient'"
            outlined
            block
              @click.prevent="resetAndClearStick"
              :x-large="!drawer"
              class="my-1 justify-start rounded-lg text-md-body-2 text-h6"
          >
            <v-icon
              small
              left
              class="mr-3"
            >
              fa-solid fa-trash

            </v-icon>
            {{$t(`button.clearStick`)}}
          </v-btn>

          <v-btn
              outlined
              block
              @click.prevent="openSupport()"
              :x-large="!drawer"
              class="my-1 justify-start rounded-lg text-md-body-2 text-h6"
          >
            <v-icon
                small
                left
                class="mr-3"
            >
              fa-solid fa-life-ring
            </v-icon>
            {{$t(`button.support`)}}
          </v-btn>


          <v-btn
              outlined
              block
              @click.prevent="openfaq()"
              :x-large="!drawer"
              class="my-1 justify-start rounded-lg text-md-body-2 text-h6"
          >
            <v-icon
                small
                left
                class="mr-3"
            >
              fa-solid fa-list
            </v-icon>
            {{$t(`button.faq`)}}
          </v-btn>

        </div>

        <div class="mb-8 d-flex flex-column justify-center align-start">
          <div><span class="font-weight-bold green--text text--lighten-5">&copy; PatientSupportR BV</span></div>
          <div class="green--text text--lighten-5">{{$t(`app.tagline`)}}</div>
        <div class="green--text text--lighten-6"><small>v{{version}}</small></div>
        </div>
      </div>

    </v-navigation-drawer>

    <v-footer app padless inset class="d-flex flex-column">
      <div
          v-if="renderedMessage"
          class="col-12 text-center"
      >
        <div class="d-flex justify-center align-center" v-html="renderedMessage">
        </div>
      </div>
      <div
          class="col-12 text-center"
      >
        <div class="d-flex justify-center align-center">
          <a class="grey--text" v-if="!fullDisclaimer" @click.prevent="fullDisclaimer=true">{{ $t('app.disclaimer') }}</a>
          <small class="grey--text" v-if="fullDisclaimer">{{$t('app.disclaimerFull')}}<br/><a @click.prevent="openfaq">{{$t('app.faqFull')}}</a>&nbsp;&bull;&nbsp; <a @click.prevent="fullDisclaimer=false">{{ $t('label.close')}}</a></small>
        </div>
<!--        <div class="d-flex justify-center align-center">
          <span class="primary&#45;&#45;text">{{$t(`app.tagline`)}}</span>
        </div>-->
      </div>
    </v-footer>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import { marked } from 'marked';
import axios from "axios";

export default {
  i18n: i18n,
  name: "psr-header-patient-doctor",
  props: ['state', 'stick', 'role', 'message', 'version'],
  data() {
    return {
      current: 'state.initial',
      mode: null,
      serial: null,
      renderedMessage: null,
      view: false,
      firstName: null,
      lastName: '-',
      drawer: false,
      fullDisclaimer: false,
      pincode: null,
      resetDialog: false,
      provisioning: false,
      languages: ['en','nl', 'fr'],
      locked: false,
    }
  },
  methods: {
    logout() {
      this.$nextTick(() => window.location.reload());
    },
    openfaq() {
      if (this.$vuetify.breakpoint.smAndDown) {
        window.open( 'https://www.mova.care/nl/faq/');
        this.drawer = false;
      }
      window.open( 'https://www.mova.care/nl/faq/', '_blank');
    },
    openSupport() {
      if (this.$vuetify.breakpoint.smAndDown) {
        window.open( 'https://zfrmz.eu/3BiErR7r62GuGFIANFLh');
        this.drawer = false;
      }
      window.open( 'https://zfrmz.eu/3BiErR7r62GuGFIANFLh', '_blank');
    },
    resetAndClearStick() {
     this.resetDialog = true;
    },
    reallyResetAndClearStick() {
      const config = {
        headers: {
          "X-TOKEN": this.state.token
        }
      }
      axios.delete('/virtual-stick', config).then( () => {
        this.resetDialog = false;
        const updatedState = Object.assign({}, this.state);
        this.$root.$emit('update-state', {state: updatedState, reply: true});
        this.$root.$emit('force-reset');
      });
    },
    languageChanged() {
      const updatedState = Object.assign({}, this.state);
      updatedState.lang = this.$i18n.locale;
      this.$root.$emit('update-state', {state: updatedState, reply: true});
    },

    showMenu(){
      this.$root.$emit('show-menu')
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = false;
      }
    }
  },
  mounted() {
    if (this.message && this.message.length) {
      this.renderedMessage = marked.parse(this.message)
    }
    const that = this;
    this.serial = this.state.serial;
    this.$root.$on('identify', (identification) => {
      that.firstName = identification.firstName;
      that.lastName = identification.lastName;
    });
    this.$root.$on('lock', () => {
      this.locked = true;
    });
  }
}
</script>

<style scoped>
.full-height {
  height: 100%;
}
.v-toolbar__content {
  padding: 0px !important;
}
.v-input {
  font-size: 1.5rem !important;
}
.fixed-font {
  font-size: 1.5rem !important;
}
</style>
