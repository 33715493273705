<template>
  <div>
    <div v-if="!state">loading...</div>
    <psr-header-initial v-if="!role" :state="state" :stick="stick" :version="version"></psr-header-initial>
    <psr-header-patient-doctor v-if="role === 'patient' || role === 'doctor'" :role="role" :state="state" :stick="stick" :message="message" :version="version"></psr-header-patient-doctor>
    <psr-main v-if="stick" :state="state" :version="version"></psr-main>
    <idle-modal></idle-modal>
  </div>
</template>

<script>

import i18n from "./services/i18n";

export default {
  name: 'start',
  props: ['version'],
  i18n:i18n,
  components: {
  },
  mounted() {
    this.$root.$on('set-role', (value) => {
      this.role = value.role;
    });
    this.$root.$on('update-state', (value) => {
      this.state = value.state;
    });
  },

  data() {
    return {
      state: {},
      stick: true,
      message: null,
      role: null,
    }
  }
};
</script>
<style>
.v-main {
  background-color: #F8F8F8;
}
</style>
