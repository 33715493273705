import Vue from 'vue'

import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'

import { componentsPlugin } from "@/components/components";
Vue.use( componentsPlugin )
import start from "@/start.vue";
Vue.component(start.name, start)
import VueMask from 'v-mask';
Vue.use(VueMask);

import VueMoment from 'vue-moment';
Vue.use(VueMoment);
Vue.config.productionTip = false

Vue.config.productionTip = false


if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
}
