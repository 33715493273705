<template>
  <div>

    <v-dialog v-model="uploadConfirmDialog" max-width="900">
      <v-card v-if="form.inputFile" :loading="uploading">
        <v-card-title class="secondary white--text">
          {{$t('import.confirm')}}
        </v-card-title>
        <v-card-text  v-if="state.countryIn === 'BE'">
          <h2 class="mt-2">
            {{$t('import.messageBE')}}
          </h2>
          <div>"{{ form.inputFile.name }}"</div>
        </v-card-text>
        <v-card-text  v-if="state.countryIn === 'NL'">
          <h2 class="mt-2">
            {{$t('import.messageNL')}}.
            <div>"{{ form.inputFile.name }}"</div>
            <div v-if="form.inputFileExtra">"{{ form.inputFileExtra.name }}"</div>
          </h2>
        </v-card-text>
        <v-card-text v-if="uploading">
          <v-progress-linear
            v-model="progress"
            color="blue-grey"
            max="100"
            height="25"
            :query="progress > 99"
          >
            <template v-slot:default="{ value }">
              <strong v-if="progress < 99">{{ Math.ceil(value) }}%</strong>
              <strong v-else>{{$t('label.encrypting')}}</strong>
            </template>
          </v-progress-linear>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn  :disabled='uploading' class="rounded-lg" color="secondary" text @click="uploadConfirmDialog = false">{{ $t('label.cancel') }}</v-btn>
          <v-btn :disabled='uploading' class="rounded-lg" color="secondary" outlined @click="confirm">{{ $t('label.confirm') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex justify-center align-center h-100">
      <div v-if="!submitted" class="pa-3 col-12">
        <v-card
            class="rounded-lg"
            elevation="4"
        >
          <v-card-text>
            <template v-if="state.countryIn == 'BE'" >
              <div class="d-flex align-center">
                <div class="col-3">
                  {{$t('import.fileBE')}}
                </div>

                <div class="col-8">
                  <v-file-input
                      block
                      text
                      accept=".xml, .zip"
                      outlined
                      class="rounded-lg"
                      :disabled="uploading"
                      v-model="form.inputFile"
                  >{{form.inputFile ? form.inputFile : $t('label.selectFile')}}</v-file-input>
                </div>
              </div>
            </template>

            <template v-if="state.countryIn == 'NL'" >
              <div class="mb-6">
                <h3>{{$t('import.infoExportNL')}} <a class="secondary--text" @click="openExternal('https://www.patientSupportr.eu')">PatientSupportr website</a></h3>
              </div>
              <div class="d-flex align-center">
                <div class="col-3">
                  {{$t('import.fileNL')}}
                </div>

                <div class="col-8">
                  <v-file-input
                      block
                      text
                      outlined
                      :disabled="uploading"
                      class="rounded-lg"
                      v-model="form.inputFile"
                  >{{form.inputFile ? form.inputFile : $t('label.selectFile')}}</v-file-input>
                </div>
              </div>
              <div class="d-flex align-center">
                <div class="col-3">
                  {{$t('import.fileZipNL')}}
                </div>

                <div class="col-8">
                  <v-file-input
                      block
                      text
                      outlined
                      :disabled="uploading"
                      v-model="form.inputFileExtra"
                  >{{form.inputFileExtra ? form.inputFileExtra : $t('label.selectFile')}}</v-file-input>
                </div>
              </div>
            </template>

            <v-progress-linear v-if="uploading" :value="uploaded"></v-progress-linear>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!uploading" x-large outlined class="rounded-lg" color="secondary" @click="uploadConfirmDialog = true"
                   :disabled="!form.inputFile" block>
              {{ $t('label.confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="submitted" class="pa-3 col-6">
        <v-card
            class="rounded-lg"
            elevation="4"
        >
          <v-card-text>
            <h3>{{$t("upload.submitted")}}</h3>
          </v-card-text>
          <v-card-actions>
            <v-btn @click.prevent="lock" color="secondary" class="rounded-lg" outlined block>{{$t('label.lock')}}</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import axios from "axios";

export default {
  name: "dossier-upload",
  i18n: i18n,
  components: {
    // PincodeInput
  },
  props: ['state', 'csrf', 'doctor'],
  data() {
    return {
      progress: 0,
      submitted: false,
      form: {
        name: null,
        riziv: null,
        inputFile: null,
        inputFileExtra: null
      },
      activationCode: '',
      activated: false,
      requested: false,
      validForm: false,
      uploadConfirmDialog: false,
      uploading: false,
      uploaded: 0
    }
  },
  watch: {
    activationCode: async function (newVal) {
      if (newVal.length === 9) {
        this.activationCode = '';
        this.activated = true;
        console.log('activated')
      }
    }
  },
  methods: {
    sendRequest() {
      this.$root.$emit('send-request', {type: 'consent', target: this.doctor});
      this.requested = true;
    },
    lock() {
      this.$root.$emit('lock')
    },
    openExternal(url) {
      window.ipcRenderer.send("open-ex-link", url);
    },
    async confirm() {
      const that = this;
      this.uploading = true;
      this.submitted = true;
      var data = new FormData();
      data.append("inputFile", this.form.inputFile)
      if (this.form.inputFileExtra) {
        data.append("inputFileExtra", this.form.inputFileExtra);
      }
      data.append("uploadedBy", this.doctor);
      data.append("serial", this.state.movaId)
      const config = {
        onUploadProgress: progressEvent => {
          that.progress = (progressEvent.loaded * 100.0 / progressEvent.total)
        },
        headers: {
          "X-TOKEN": this.state.token
        }
      }
      await axios.post("/virtual-stick/medrecord", data, config);
      this.$root.$emit('updated-medrecord')
      var updatedState = Object.assign({}, this.state);

      updatedState.phase = 'app';
      this.$root.$emit('update-state', {state: updatedState})
      this.uploading = false;
    }
  }
}
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}
.h-100 {
  height: calc(100vh - 50px);
}
</style>
