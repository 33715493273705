<template>
  <v-container fluid class="ma-0 pa-0 ">
    <template v-if="state&& !locked && !challenged && !secret">
      <!--      initial state: unlock stick as patient or physician -->
      <v-row class="justify-center align-stretch my-12 flex-wrap">
        <div class="pa-3 col-10 col-lg-3">
          <v-card class="pa-3 rounded-lg" elevation="4" height="100%">
            <v-card-text class="d-flex flex-column justify-space-around align-center pa-6">
              <div class="pa-3">
                <v-img height="100" contain src="/base/img/patient.svg"></v-img>
              </div>
              <div class="mt-3">
                <h1 class="font-weight-bold text-sm-h3 text-md-h5">{{ $t(`question.patient`) }}</h1>
              </div>
              <div class="mt-3">
                <v-btn
                  dark
                  x-large
                  color="primary"
                  class="ma-2 rounded-lg text-sm-h4 text-md-body-1"
                  elevation="4"
                  @click.prevent="role = 'patient'"
                >
                  <v-icon
                    left
                    dark
                  >
                    fa-solid fa-message
                  </v-icon>
                  {{ $t(`button.confirmSms`) }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="pa-3 col-10  col-lg-3">
          <v-card class="pa-3 rounded-lg" elevation="4" height="100%">
            <v-card-text class="d-flex flex-column justify-space-around align-center pa-6">
              <div class="pa-3">
                <v-img height="100" contain src="/base/img/physician.svg"></v-img>
              </div>
              <div class="mt-3">
                <h1 class="font-weight-bold  text-sm-h3 text-md-h5">{{ $t(`question.doctor`) }}</h1>
              </div>
              <div class="mt-3">
                <v-btn
                  dark
                  color="secondary"
                  x-large
                  class="ma-2 rounded-lg text-sm-h4 text-md-body-1"
                  elevation="4"
                  @click.prevent="role = 'doctor'"
                >
                  <v-icon
                    left
                    dark
                  >
                    fa-solid fa-message
                  </v-icon>
                  {{ $t(`button.confirmSms`) }}
                </v-btn>
              </div>
              <div class="mt-2">
                <h3 v-if="$vuetify.breakpoint.smAndDown">{{ $t(`label.smsSendToPatient`) }}</h3>
                <h4 v-else>{{ $t(`label.smsSendToPatient`) }}</h4>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-row>
    </template>
    <template v-if="state && role && !challenged">
      <!--      initial state: start SMS request flow  -->
      <v-dialog
        value="true"
        persistent
        :width="$vuetify.breakpoint.mobile ? '90%' : '50%'"
        overlay-color="secondary"
      >
        <v-card flat class="rounded-lg">
          <v-card-title v-if="role === 'doctor'">
            {{ $t(`question.doctorActivation`) }}
          </v-card-title>
          <v-card-title v-if="role !== 'doctor'">
            {{ $t(`question.patientActivation`) }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              :label="$t(`label.enterMovaID`)"
              hide-details outlined
              autofocus
              autocapitalize="off"
              autocorrect="off"
              v-model="movaId"
            ></v-text-field>
            <v-text-field :label="$t('import.namePhy')"
                          v-if="role === 'doctor'"
                          class="pt-2 rounded-lg"
                          hide-details outlined v-model="nameDoctor" required
                          color="secondary"></v-text-field>
          </v-card-text>
          <v-card-actions class="px-6 pb-3 d-flex flex-row flex-wrap justify-center align-center">
            <v-btn
              color="secondary"
              class="rounded-lg flex-grow-1"
              elevation="4"
              x-large
              @click.prevent="challenge(role)"
              :disabled="!readyForActivation"
            >
              <v-icon
                left
                dark
              >
                fa-solid fa-message
              </v-icon>
              {{ $t(`button.confirmSms`) }}
            </v-btn>
            <v-btn
              outlined
              color="secondary"
              class="rounded-lg"
              elevation="4"
              x-large
              @click.prevent="logout"
            >
              {{ $t(`label.cancel`) }}
            </v-btn>


          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="state && challenged && !secret && !notPaid">
      <!--      initial state: enter request response  -->
      <div class="d-flex justify-center align-stretch my-12 ">
        <div class="pa-3 col-8 col-md-6 col-lg-6">
          <v-card class="pa-3 rounded-lg" elevation="4" height="100%">
            <v-card-text class="d-flex flex-column justify-space-around align-center pa-6">
              <div class="pa-3">
                <v-icon x-large>
                  fa-solid fa-message
                </v-icon>
              </div>
              <div class="mt-3 text-center">
                <span class="font-weight-bold text-md-h5 text-h4">{{ $t(`label.enterCode`) }}</span>
              </div>
              <div class="pa-3">
                <v-otp-input
                  autofocus
                  length="6"
                  type="number"
                  @finish="activate()"
                  v-model="activationCode"
                ></v-otp-input>
              </div>
              <div class="d-flex justify-center align-center pa-3">
                <span class="text-caption text-md-h6 text-h4">{{ $t(`label.noCode`) }} <a
                  class="text-decoration-underline"
                  :class="role === 'patient' ? 'primary--text' : 'secondary--text'"
                  @click.prevent="challenge(role)">{{ $t(`label.resend`) }}</a></span>
              </div>
              <div class="d-flex justify-center align-center pa-3">
                <span class="text-caption text-md-h6 text-h4"><a class="text-decoration-underline"
                                                                 :class="role === 'patient' ? 'primary--text' : 'secondary--text'"
                                                                 href="/">{{ $t(`label.restart`) }}</a></span>
              </div>
              <div class="d-flex justify-center align-center pa-3">
                <span v-if="isError" class="error--text">{{ $t('stick.activationFailed') }}</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </template>
    <template v-if="secret && state">
      <!--      go to the application menu-->
      <psr-menu-patient-doctor v-if="role === 'patient' || role === 'doctor'" :role="role" :state="state"
                               :doctor="nameDoctor"></psr-menu-patient-doctor>
    </template>
  </v-container>
</template>

<script>
import i18n from "@/services/i18n";
import axios from 'axios';

export default {
  name: "psr-main",
  i18n: i18n,
  props: ['state'],
  components: {},
  data() {
    return {
      isError: false,
      notPaid: false,
      movaId: null,
      secret: false,
      challenged: false,
      message: null,
      locked: false,
      activationCode: '',
      activating: false,
      role: null,
      nameDoctor: '',
    }
  },
  computed: {
    readyForActivation() {
      //TBD: validation
      return true;
    }
  },
  mounted() {
    this.state = Object.assign(this.state, this.state);
    this.$root.$on('lock', () => {
      this.secret = false;
      this.locked = true;
      this.challenged = false;
    });
    this.$root.$on('set-error', () => {
      this.isError = true;
    });
  },
  methods: {
    logout() {
      window.location.reload();
    },
    async challenge(role) {
      const that = this;
      this.activationCode = '';
      that.role = role;
      var result = await axios.post('/virtual-stick/challenge', {
        code: this.movaId,
        target: this.nameDoctor,
        type: this.role === 'doctor' ? "consent" : "access"
      })
      if (result.data && result.data.cmd === 'go') {
        this.challenged = true;
      }
    },

    async activate() {
      var data = {
        serial: this.movaId,
        challengeResponse: this.activationCode
      }
      const that = this;
      axios.post('/virtual-stick/validateCode', data)
        .then((res) => {
          this.activationCode = '';
          if (res) {
            if (res.data.status !== "ok") {
              //set the payment status message
              this.notPaid = res.data.status;
            } else {
              this.message = res.message;
              this.activating = true;
              this.state.token = res.data.token;
              this.state.movaId = this.movaId;
              this.state.mode = res.data.mode;
              var mode_splitted = this.state.mode.split("_");
              this.state.service = mode_splitted[0];
              this.state.countryIn = mode_splitted[1];
              if (mode_splitted.length == 3) {
                this.state.countryOut = mode_splitted[2];
              }
              this.secret = true;
              this.activationCode = '';
              this.activating = false;
              this.$root.$emit('set-role', {role: this.role})
              this.$root.$emit('set-message', res.message)
            }
          }
        })
        .catch(() => {
          that.activationCode = '';
        })
    }
  }
}
</script>

<style scoped>
.iconImg {
  height: 400px;
}

.larger--text {
  font-size: 130%;
}

.v-text-field__slot {
  min-width: 60px;
}
</style>
