

<template>
  <div>
    <psr-header-initial></psr-header-initial>
    <div class="d-flex justify-center align-center h-100">
      <div class="pa-3 col-12">
        <v-card
          class="rounded-lg"
          elevation="4"
        >
          <v-car-title>
            <h3 class="pa-6 col-12">{{$t(this.value)}}</h3>
          </v-car-title>
        </v-card>
      </div>
    </div>
</div>
</template>

<script>
import i18n from "@/services/i18n";

export default {
  i18n: i18n,
  name: "simple-message",
  props: ['value']
}
</script>
<style scoped>

</style>
