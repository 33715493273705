<template>
  <div>

  <div class="d-flex justify-center align-center h-100">
      <div class="pa-3 col-12">
        <v-card
            :loading="hasContent && !hasExport"
            class="rounded-lg"
            elevation="4"
        >
          <v-card-text>
            <template  >
              <div class="d-flex align-center flex-column">
                <div class="my-3">
                  <h2 v-if="state.countryOut == 'BE'">{{$t('export.fileBE')}}</h2>
                  <h2 v-if="state.countryOut == 'NL'">{{$t('export.fileNL')}}</h2>
                </div>
                <div>
                  <strong v-if="hasContent && !hasExport" class="red--text">{{$t('label.processing')}}</strong>
                  <p v-if="hasExport">{{$t('label.uploaded')}}: {{contentDate | moment("DD/MM/YYYY")}}</p>
                </div>
              </div>
            </template>
          </v-card-text>
          <v-card-actions>
            <v-btn @click.prevent="downloadFile" block color='secondary' :disabled="!hasExport">
              {{ $t('label.download') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>

    </div>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import axios from "axios";

export default {
  name: "dossier-convert",
  i18n: i18n,
  components: {
  },
  props: ['state', 'csrf', 'doctor'],
  data() {
    return {
      hasExport: false,
      hasContent: false,
    }
  },
  methods: {
    downloadFile() {
      axios({
        url: "/virtual-stick/export?serial="+this.state.movaId,
        method: 'GET',
        responseType: 'blob',
        headers: { "X-TOKEN": this.state.token }
      }).then(response => {
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    }
  },
  async mounted() {
    const that = this;
    const config = {
      headers: {
        "X-TOKEN": this.state.token
      }
    }
    axios.get("/virtual-stick/status?serial="+this.state.movaId, config).then(res => {
      that.hasExport = res.data.hasExport;
      that.hasContent = res.data.hasMedrecord;
      that.contentDate = res.data.lastUpload;
      that.hasSummary = res.data.hasSummary;
    });
  }
}
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}
.h-100 {
  height: calc(100vh - 50px);
}
</style>
