<template>
  <v-dialog v-model="idleShow" max-width="600">
    <v-card tile>
      <v-card-title>{{ $t('idle.title') }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-3">
        {{ $t('idle.body') }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';
import IdleVue from 'idle-vue';
import i18n from "@/services/i18n";

const idleEvents = new Vue()

Vue.use(IdleVue, {
  idleTime: 6 * 60 * 1000,
  eventEmitter: idleEvents,
  timer: null
});

export default {
  name: "idle-modal",
  i18n: i18n,
  props: [],
  data() {
    return {
      idleShow: false,
      idleInterval: 0
    }
  },

  methods: {
    timerIncrement: function () {
      console.log('idle timer triggered')
      this.$nextTick(() => window.location.reload());
    }
  },
  onIdle() {
    this.idleShow = true;
    // Start a timer, counting every minute
    this.timer = setInterval(this.timerIncrement, 60 * 1000); // 1 minute
  },
  onActive() {
    // Set interval on zero
    this.idleShow = false;
    this.idleInterval = 0;
    // Reset the timer
    if (this.timer !== null){
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
</script>
