<template>
  <div>
    <v-dialog v-model="manageRequestDialog" max-width="900">
      <v-card>
        <v-card-title class="secondary white--text">
          {{ $t('import.confirmRequest') }}
        </v-card-title>
        <v-card-text>
          <p>{{$t('label.uploaded')}}: {{requestDate | moment("DD/MM/YYYY")}}</p>
          <p>{{$t('label.by')}}: {{requestPhysician}}</p>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn :disabled='uploading' class="rounded-lg" color="secondary" text @click="manageRequest(false)">
            {{ $t('label.decline') }}
          </v-btn>
          <v-btn :disabled='uploading' class="rounded-lg" color="secondary" outlined @click="manageRequest(true)">
            {{ $t('label.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="application === null">
      <div class="d-flex justify-center align-center">
        <div v-if="!hasContent" class="pa-3 col-6 col-md-4 col-lg-3">
          <v-alert
              type="warning"
              dense
              class="rounded-lg"
              elevation="4"
          >{{ $t('label.noContent') }}</v-alert>
        </div>
      </div>
      <div class="d-flex justify-center align-center h-100">
        <div class="d-flex flex-column justify-center align-stretch wrap pa-0 ma-0">
          <div v-if="role === 'doctor'" class="pa-3">
            <v-card class="pa-3 rounded-lg" elevation="4" height="100%" link @click.prevent="openUpload">
              <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <div class="pa-3">
                  <v-icon x-large :color="role === 'patient' ? 'primary' : 'secondary'">
                    fa-solid fa-upload
                  </v-icon>
                </div>
                <div class="mt-3 text-center" :class="role === 'patient' ? 'primary--text' : 'secondary--text'">
                  <h3>{{$t(`label.uploadDossier`)}} ({{this.sourceCountry}})</h3>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div v-if="role === 'doctor' && canExport" class="pa-3 ">
            <v-card class="pa-3 rounded-lg" elevation="4" height="100%" link @click.prevent="openConvert" :disabled="!hasContent">
              <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <div class="pa-3">
                  <v-icon x-large :color="role === 'patient' ? 'primary' : 'secondary'">
                    fa-solid fa-download
                  </v-icon>
                </div>
                <div class="mt-3 text-center" :class="role === 'patient' ? 'primary--text' : 'secondary--text'">
                  <h3>{{$t(`label.exportDossier`)}} ({{this.destCountry}})</h3>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div class="pa-3 ">
            <v-card class="pa-3 rounded-lg" elevation="4" height="100%" link @click.prevent="openViewer" :disabled="!hasSummary" :loading="hasContent && !hasSummary">
              <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <div class="pa-3">
                  <v-icon x-large :color="role === 'patient' ? 'primary' : 'secondary'">
                    fa-solid fa-folder-open
                  </v-icon>
                </div>
                <div class="mt-3 text-center" :class="role === 'patient' ? 'primary--text' : 'secondary--text'">
                  <div class="text-h3 text-md-h5" v-if="role === 'patient'">{{$t(`label.showDossierPhysician`)}}</div>
                  <div v-else class="text-h3 text-md-h5">{{$t(`label.showDossier`)}}</div>
                  <strong class="text-h4 text-md-body-1 red--text" v-if="hasContent && !hasSummary"><i>{{$t('label.processing')}}</i></strong>
                  <p class="text-h4 text-md-body-1" v-if="hasContent"><i>{{$t('label.uploaded')}}: {{contentDate | moment("DD/MM/YYYY")}}</i></p>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div class="pa-3 " v-if="role== 'patient'">
            <v-card class="pa-3 rounded-lg" elevation="4" height="100%" link @click.prevent="sendRequest">
              <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <div class="pa-3">
                  <v-icon x-large :color="role === 'patient' ? 'primary' : 'secondary'">
                    fa-solid fa-envelope
                  </v-icon>
                </div>
                <div class="mt-3 text-center" :class="role === 'patient' ? 'primary--text' : 'secondary--text'">
                  <div class="text-h3 text-md-h5">{{$t(`label.physicianRequest`)}}</div>
                  <p class="text-h4 text-md-body-1" v-if="hasOpenRequest"><i>{{$t('label.physicianOpenRequest')}} {{openRequestSent | moment("DD/MM/YYYY")}}</i></p>
                </div>
              </v-card-text>
            </v-card>
          </div>
          <div class="pa-3 " v-if="role== 'patient' && hasRequestReady">
            <v-card class="pa-3 rounded-lg" elevation="4" height="100%" link @click.prevent="manageRequestDialog = true">
              <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <div class="pa-3">
                  <v-icon x-large :color="role === 'patient' ? 'primary' : 'secondary'">
                    fa-solid fa-folder-open
                  </v-icon>
                </div>
                <div class="mt-3 text-center" :class="role === 'patient' ? 'primary--text' : 'secondary--text'">
                  <div class="text-h3 text-md-h5">{{$t(`label.importDossier`)}}</div>
                    <div><i>{{$t('label.uploaded')}}: {{requestDate | moment("DD/MM/YYYY")}}</i>
                    <div class="mt-0 text-h4 text-md-body-1"><i>{{$t('label.by')}}: {{requestPhysician}}</i></div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
    </template>
    <template v-if="application === 'viewer'">
      <psr-view :state="state" :role="role"></psr-view>
    </template>
    <template v-if="application === 'upload'">
      <dossier-upload :state="state" :doctor="doctor"></dossier-upload>
    </template>
    <template v-if="application === 'dossierRequest'">
      <dossier-request :state="state"></dossier-request>
    </template>
    <template v-if="application === 'convert'">
      <dossier-convert :state="state" :doctor="doctor"></dossier-convert>
    </template>
    <template v-if="showPdf">
      <v-dialog
        value="true"
        persistent
        scrollable
        :overlay-color="role === 'patient' ? 'primary' : 'secondary'"
        class="elevation-0 rounded-0"
        content-class="elevation-0 rounded-0"
      >
        <v-card tile color="transparent" flat class="rounded-0">
          <v-card-title class="justify-end transparent px-0 py-3">
            <v-btn class="rounded-lg mr-3" :class="role === 'patient' ? 'primary--text' : 'secondary--text'" color="white" depressed @click.prevent="downloadPdf()">{{ $t('menu.download') }}</v-btn>
<!--            <v-btn class="rounded-lg mr-3" :class="role === 'patient' ? 'primary&#45;&#45;text' : 'secondary&#45;&#45;text'" color="white" depressed @click.prevent="$refs.myPdfComponent.print()">{{ $t('menu.print') }}</v-btn>-->
            <v-btn class="rounded-lg" :class="role === 'patient' ? 'primary--text' : 'secondary--text'" color="white" depressed @click.prevent="showPdf=false">{{ $t('menu.back') }}</v-btn>
          </v-card-title>

          <v-card-text class="pa-0">
            <pdf ref="myPdfComponent" :src="{data: pdfData}"></pdf>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>

  </div>
</template>
<script>
import i18n from "@/services/i18n";
import pdf from 'vue-pdf'
import axios from "axios";

export default {
  i18n: i18n,
  name: "psr-menu-patient-doctor",
  props: ['state', 'role', 'doctor'],
  components: {pdf},
  data() {
    return {
      persInfoParsed: null,
      validPhys: false,
      hasContent: false,
      hasSummary: false,
      uploadConfirmDialog: false,
      uploadingPhysicianKnown: false,
      application: null,
      showPdf: false,
      rightAfterExport: false,
      inputFile: null,
      uploaded: 0,
      uploading: false,
      timer: null,
      exportPhy: null,
      exportDate: null,
      uploadPhy: null,
      uploadId: null,
      uploadDate: null,
      pdfData: null,
      canExport: false,
      openRequestSent: null,
      sourceCountry: 'BE',
      destCountry: 'NL',
      hasRequestReady: false,
      requestDate: null,
      hasOpenRequest: false,
      manageRequestDialog: false,
      requestPhysician: null,
      recycle: false,
      requestId: null
    }
  },
  mounted() {
    const that = this;
    this.fetchState();
    this.canExport = this.state.mode.startsWith('CONVERT')
    this.sourceCountry = this.state.mode.split("_")[1];
    console.log("got state", this.state.mode);
    if (this.canExport) {
      this.destCountry = this.state.mode.split("_")[2];
    }
    this.$root.$on('updated-medrecord', () => {
      that.application = null;
      that.fetchState();
    });

    this.$root.$on('show-pdf', (pdfData) => {
      this.pdfData = atob(pdfData);
      this.showPdf = true;
    })

    this.$root.$on('show-menu', () => {
      this.application = null;
      this.fetchState();
    })
    this.$root.$on('force-reset', () => {
      this.application = null;
      this.fetchState();
    })
  },
  watch: {
    state: function (newVal) {
      this.fetchState();
      this.openPanels = [0];
      if (newVal.persInfo && newVal.persInfo.name) {
        const tmp = newVal.persInfos
        //TODO: fix this null string issue
        this.persInfoParsed = `${tmp.name} (${tmp.rrn && tmp.rrn !== 'null' ? tmp.rrn : ''} ${tmp.BSN && tmp.BSN !== 'null' ? tmp.BSN : ''})`
      }
    }
  },
  methods: {
    fetchState() {
      const that = this;
      if (this.timer) {
        delete this.timer;
      }
      if (this.state.token) {
        const config = {
          headers: {
            "X-TOKEN": this.state.token
          }
        }
        axios.get("/virtual-stick/status?serial="+this.state.movaId, config).then(res => {
          if (res.data.lastName && res.data.lastName.length) {
            this.$root.$emit('identify', res.data);
          }
          that.hasContent = res.data.hasMedrecord;
          that.contentDate = res.data.lastUpload;
          that.hasSummary = res.data.hasSummary;
          that.hasOpenRequest = res.data.hasOpenRequest;
          that.openRequestSent = res.data.openRequestSent;
          that.hasRequestReady = res.data.hasRequestReady;
          that.requestPhysician = res.data.requestPhysician;
          that.requestDate = res.data.requestDate;
          that.requestId = res.data.requestId;
          this.timer = setTimeout( () => {that.fetchState()}, 5000);
        });
      }
    },
    downloadPdf() {
      var a = document.createElement("a"); //Create <a>
      a.href = "data:application/pdf;base64," + btoa(this.pdfData); //Image Base64 Goes here
      a.download = "report.pdf"; //File name Here
      a.click(); //Downloaded file
    },
    manageRequest(keep) {
      const that = this;
      const config = {
        headers: {
          "X-TOKEN": this.state.token
        }
      }
      axios.patch(`/virtual-stick/physician-request/${this.requestId}?serial${this.state.movaId}&import=${keep}`, {}, config).then( () => {
        that.manageRequestDialog = false;
        that.fetchState();
      });
    },
    openViewer() {
      this.application = 'viewer'
    },
    sendRequest() {
      this.application='dossierRequest'
    },
    openUpload() {
      this.application = 'upload'
    },
    openConvert() {
      this.application = 'convert'
    },
    async resetStick() {
      //TBD
    }
  }
}

</script>

<style scoped>
.pdfCard {
  overflow: auto;
}
.h-100 {
 height: calc(100vh - 50px);
}
</style>
