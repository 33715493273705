<template>
  <div>

  <div class="d-flex justify-center align-center h-100">
      <div class="pa-3 col-12">
        <v-card
            class="rounded-lg"
            elevation="4"
        >
          <v-card-text v-if="sent">
            <h3>{{$t('label.requestSent')}}</h3>
          </v-card-text>
          <v-card-text v-if="!sent">
            <template  >
              <v-form v-model="formValid" class="d-flex align-center flex-column">
                <div class="my-3 col-md-6">
                  <v-text-field  :label="$t(`label.enterPhysicianEmail`)"
                                 hide-details outlined
                                 autofocus
                                 :rules="emailRule"
                                 v-model="form.physicianEmail">
                  </v-text-field>
                </div>
                <div class="my-3 col-md-6">
                  <v-text-field  :label="$t(`label.enterName`)"
                                 hide-details outlined
                                 :rules="requiredRule"
                                 v-model="form.senderName">
                  </v-text-field>
                </div>
                <div class="my-3 col-md-6">
                  <v-text-field  :label="$t(`label.birthDate`)"
                                 hide-details outlined
                                 ref="bd"
                                 :rules="requiredRule"
                                 type="date"
                                 v-model="form.birthDate">
                  </v-text-field>
                </div>
                <div class="my-3 col-md-6">
                  <v-textarea  :label="$t(`label.enterNote`)"
                                 hide-details outlined
                               dense
                                 v-model="form.note">
                  </v-textarea>
                </div>
              </v-form>
            </template>
          </v-card-text>
          <v-card-actions  v-if="!sent">
            <v-btn @click.prevent="sendRequest" block color='primary' :disabled="!formValid">
              {{ $t('label.send') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>

    </div>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import axios from "axios";
export default {
  name: "dossier-request",
  i18n: i18n,
  components: {
  },
  props: ['state'],
  data() {
    return {
      formValid: false,
      sent: false,
      emailRule: [
        v => !!v || 'E-mail is required',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
      ],
      requiredRule: [
        v => !!v || 'Error'
      ],
      form: {
        serial: null,
        physicianEmail: null,
        senderName: null,
        birthDate: null,
        note: null
      }
    }
  },
  computed: {
  },
  methods: {
    sendRequest() {
      const that = this;
      const config = {
        headers: {
          "X-TOKEN": this.state.token
        }
      }
      this.form.serial = this.state.movaId
      axios.post('/virtual-stick/physician-request', this.form, config).then( () => {that.sent = true;});
    }
  },
  async mounted() {
    const bd = this.$refs.bd.$el.querySelector('bd')
    bd.max= new Date().toISOString().split('T')[0];
  }
}
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}
.h-100 {
  height: calc(100vh - 50px);
}
</style>
