<template>
  <div>
    <psr-header-initial></psr-header-initial>
    <v-dialog v-model="uploadConfirmDialog" max-width="900">
      <v-card v-if="form.inputFile" :loading="uploading">
        <v-card-title class="secondary white--text">
          {{ $t('import.confirm') }}
        </v-card-title>
        <v-card-text v-if="countryIn === 'BE'">
          <h2 class="mt-2">
            {{ $t('import.messageBE') }}
          </h2>
          <div>"{{ form.inputFile.name }}"</div>
        </v-card-text>
        <v-card-text v-if="countryIn === 'NL'">
          <h2 class="mt-2">
            {{ $t('import.messageNL') }}.
            <div>"{{ form.inputFile.name }}"</div>
            <div>"{{ form.inputFileExtra.name }}"</div>
          </h2>
        </v-card-text>
        <v-card-text v-if="uploading">
          <v-progress-linear
            v-model="progress"
            color="blue-grey"
            max="100"
            height="25"
            :query="progress > 99"
          >
            <template v-slot:default="{ value }">
              <strong v-if="progress < 99">{{ Math.ceil(value) }}%</strong>
              <strong v-else>{{ $t('label.encrypting') }}</strong>
            </template>
          </v-progress-linear>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn :disabled='uploading' class="rounded-lg" color="secondary" text @click="uploadConfirmDialog = false">
            {{ $t('label.cancel') }}
          </v-btn>
          <v-btn :disabled='uploading' class="rounded-lg" color="secondary" outlined @click="confirm">
            {{ $t('label.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-flex justify-center align-center h-100">
      <div v-if="!submitted" class="pa-3 col-12">
        <v-card
          class="rounded-lg"
          elevation="4"
        >
          <v-car-title>
            <h3 class="pa-6 col-12">{{$t('label.requestIntro')}}</h3>
          </v-car-title>
          <v-card-text>
            <div class="d-flex align-center flex-column">
              <div class="d-flex flex-row pa-2 col-12">
                <div class="col-3">
                  {{ $t('label.name') }}
                </div>
                <div class="col-8">
                  <v-text-field outlined tiled hide-details v-model="physicianName"></v-text-field>
                </div>
              </div>
              <div class="d-flex flex-row col-12">
                <template v-if="countryIn == 'BE'">
                  <div class="col-3">
                    {{ $t('import.fileBE') }}
                  </div>

                  <div class="col-8">
                    <v-file-input
                      block
                      text
                      outlined
                      accept=".xml, .zip"
                      class="rounded-lg"
                      :disabled="uploading"
                      v-model="form.inputFile"
                    >{{ form.inputFile ? form.inputFile : $t('label.selectFile') }}
                    </v-file-input>
                  </div>
                </template>

                <template v-if="countryIn == 'NL'">
                  <div class="mb-6">
                    <h3>{{ $t('import.infoExportNL') }} <a class="secondary--text"
                                                           @click="openExternal('https://www.patientSupportr.eu')">PatientSupportr
                      website</a></h3>
                  </div>
                  <div class="d-flex align-center">
                    <div class="col-3">
                      {{ $t('import.fileNL') }}
                    </div>

                    <div class="col-8">
                      <v-file-input
                        block
                        text
                        outlined
                        :disabled="uploading"
                        class="rounded-lg"
                        v-model="form.inputFile"
                      >{{ form.inputFile ? form.inputFile : $t('label.selectFile') }}
                      </v-file-input>
                    </div>
                  </div>
                  <div class="d-flex align-center">
                    <div class="col-3">
                      {{ $t('import.fileZipNL') }}
                    </div>

                    <div class="col-8">
                      <v-file-input
                        block
                        text
                        outlined
                        :disabled="uploading"
                        v-model="form.inputFileExtra"
                      >{{ form.inputFileExtra ? form.inputFileExtra : $t('label.selectFile') }}
                      </v-file-input>
                    </div>
                  </div>
                </template>
              </div>

              <v-progress-linear v-if="uploading" :value="uploaded"></v-progress-linear>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!uploading" x-large outlined class="rounded-lg" color="secondary"
                   @click="uploadConfirmDialog = true"
                   :disabled="!form.inputFile" block>
              {{ $t('label.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="submitted" class="pa-3 col-6">
        <v-card
          class="rounded-lg"
          elevation="4"
        >
          <v-card-text>
            <h3>{{ $t("upload.submitted") }}</h3>
          </v-card-text>
<!--          <v-card-actions>-->
<!--            <v-btn @click.prevent="lock" color="secondary" class="rounded-lg" outlined block>{{ $t('label.lock') }}-->
<!--            </v-btn>-->
<!--          </v-card-actions>-->
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/services/i18n";
import axios from "axios";

export default {
  name: "physician-request",
  i18n: i18n,
  components: {},
  props: ['requestToken', 'countryIn'],
  data() {
    return {
      progress: 0,
      physicianName: '',
      form: {},
      submitted: false,
      validForm: false,
      uploadConfirmDialog: false,
      uploading: false,
      uploaded: 0
    }
  },
  methods: {
    async confirm() {
      const that = this;
      this.uploading = true;
      this.submitted = true;
      var data = new FormData();
      data.append("inputFile", this.form.inputFile)
      if (this.form.inputFileExtra) {
        data.append("inputFileExtra", this.form.inputFileExtra);
      }
      data.append("uploadedBy", this.physicianName);
      const config = {
        onUploadProgress: progressEvent => {
          that.progress = (progressEvent.loaded * 100.0 / progressEvent.total)
        },
        headers: {
          "X-TOKEN": this.requestToken
        }
      }
      await axios.post("/virtual-stick/medrecord-requested", data, config);
  this.uploadConfirmDialog = false;
  this.submitted = true;
      this.uploading = false;
    }
  }
}
</script>

<style scoped>
.v-file-input {
  flex-direction: row-reverse;
}

.h-100 {
  height: calc(100vh - 50px);
}
</style>
