import psrview from "@/components/menu-items/psrview";
import PsrHeaderInitial from "@/components/PsrHeaderInitial";
import PsrMain from "@/components/PsrMain";
import PsrHeaderPatientDoctor from "@/components/PsrHeaderPatientDoctor";
import PsrMenuPatientDoctor from "@/components/PsrMenuPatientDoctor";
import DossierUpload from "@/components/menu-items/DossierUpload";
import DossierConvert from "@/components/menu-items/DossierConvert.vue";
import PhysicianRequest from "@/components/PhysicianRequest/PhysicianRequest.vue";
import DossierRequest from "@/components/menu-items/DossierRequest.vue";
import SimpleMessage from "@/components/SimpleMessage.vue";
import IdleModal from "@/components/idleModal.vue";
const componentsPlugin = {
    install(Vue) {
        Vue.component(psrview.name, psrview)

        Vue.component(DossierUpload.name, DossierUpload)
        Vue.component(PsrHeaderInitial.name, PsrHeaderInitial)
        Vue.component(PsrMain.name, PsrMain)
        Vue.component(PsrHeaderPatientDoctor.name, PsrHeaderPatientDoctor)
        Vue.component(PsrMenuPatientDoctor.name, PsrMenuPatientDoctor)
      Vue.component(DossierConvert.name, DossierConvert)
      Vue.component(PhysicianRequest.name, PhysicianRequest)
      Vue.component(DossierRequest.name, DossierRequest)

      Vue.component(SimpleMessage.name, SimpleMessage)
      Vue.component(IdleModal.name, IdleModal)

    }
}

export { componentsPlugin }
